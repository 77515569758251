import { Product } from "../types/Product";
import ProductList from "./ProductList";
import SectionHeader from "./SectionHeader";

type ClassicProductListProps = {
  products: Product[];
};

const ClassicProductList = ({ products }: ClassicProductListProps) => {
  return (
    <>
      <SectionHeader title="Classics" />
      <ProductList products={products} />
    </>
  );
};

export default ClassicProductList;
