import React from "react";

type CounterProps = {
  quantity: number;
  add: () => void;
  remove: () => void;
};

const Counter = ({ quantity, add, remove }: CounterProps) => {
  return (
    <div className="counter-container">
      <button onClick={remove} className="counter-button">
        -
      </button>
      <div className="counter-value">{quantity}</div>
      <button onClick={add} className="counter-button">
        +
      </button>
    </div>
  );
};

export default Counter;
