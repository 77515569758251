import { Theme } from "../types/Theme";
import { Product } from "../types/Product";
import { ProductCategory } from "../types/ProductCategory";
import { ProductLine } from "../types/ProductLine";
import { Size } from "../types/Size";
import { Season } from "../types/Season";

export const Products: Product[] = [
  {
    id: 1,
    name: "The Green Flag Sweater",
    description: "This sweater will make you look fly!",
    price: 79.99,
    url: "sweater-flag-green",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Sweaters,
    themes: [
      { theme: Theme.Season, season: Season.Fall, years: [2024] },
      { theme: Theme.Season, season: Season.Winter, years: [2024] },
    ],
    dateOnMarket: new Date("08/28/2024"),
    images: ["/images/sweater-flag-green.jpg"],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
  },
  {
    id: 2,
    name: "The Bright Flag Sweater",
    description: "This sweater will make you look fly!",
    price: 79.99,
    url: "sweater-flag-bright",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Sweaters,
    themes: [
      { theme: Theme.New, years: [2024] },
      { theme: Theme.Season, season: Season.Fall, years: [2024] },
      { theme: Theme.Season, season: Season.Winter, years: [2024] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("06/16/2024"),
    images: [
      "/images/sweater-flag-bright.jpg",
      "/images/sweater-flag-green.jpg",
    ],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
  },
  {
    id: 3,
    name: "Green Americana Company Tank",
    description: "These jeans will make you look fly!",
    price: 49.99,
    url: "tanktop-americanacompany-green",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Tshirts,
    themes: [
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
    ],
    dateOnMarket: new Date("06/16/2024"),
    images: ["/images/tanktop-americanacompany-green.jpg"],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
  },
  {
    id: 4,
    name: "Bright Freedom TShirt",
    description: "This tshirt will make you look fly!",
    price: 49.99,
    url: "tshirt-freedom-bright",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Tshirts,
    themes: [
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
    ],
    dateOnMarket: new Date("06/16/2024"),
    images: ["/images/tshirt-freedom-bright.jpg"],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
  },
];
