// src/contexts/CartContext.tsx

import React, { createContext, useState, ReactNode } from "react";
import { Product } from "../types/Product";
import { Size } from "../types/Size";
import { Color } from "../types/Color";

interface CartItem {
  product: Product;
  quantity: number;
  size: Size;
  color: Color;
}

interface CartContextType {
  cart: CartItem[];
  isCartOpen: boolean;
  setIsCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addToCart: (item: CartItem) => void;
  removeFromCart: (item: CartItem, removeSingle?: boolean) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);

  const addToCart = (item: CartItem) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (i) =>
          i.product.id === item.product.id &&
          i.size === item.size &&
          i.color === item.color
      );
      if (existingItem) {
        return prevCart.map((i) =>
          i.product.id === item.product.id &&
          i.size === item.size &&
          i.color === item.color
            ? { ...i, quantity: i.quantity + 1 }
            : i
        );
      }
      return [...prevCart, { ...item, quantity: 1 }];
    });
  };

  const removeFromCart = (item: CartItem, removeSingle: boolean = true) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (i) =>
          i.product.id === item.product.id &&
          i.size === item.size &&
          i.color === item.color
      );
      if (existingItem && existingItem.quantity > 1 && removeSingle) {
        return prevCart.map((i) =>
          i.product.id === item.product.id &&
          i.size === item.size &&
          i.color === item.color
            ? { ...i, quantity: i.quantity - 1 }
            : i
        );
      }
      return prevCart.filter(
        (i) =>
          i.product.id !== item.product.id ||
          i.size !== item.size ||
          i.color !== item.color
      );
    });
  };

  return (
    <CartContext.Provider
      value={{ cart, isCartOpen, setIsCartOpen, addToCart, removeFromCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
