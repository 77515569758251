export enum SortBy {
  Featured = "Featured",
  Best_Sellers = "Best_Sellers",
  Alphabetically_AZ = "Alphabetically_AZ",
  Alphabetically_ZA = "Alphabetically_ZA",
  Price_Low_to_High = "Price_Low_to_High",
  Price_High_to_Low = "Price_High_to_Low",
  Date_New_to_Old = "Date_New_to_Old",
  Date_Old_to_New = "Date_Old_to_New",
}
