import React from "react";
import { Size } from "../types/Size";
import { sizeToString } from "../extensions/helpers";

interface SizeSelectorProps {
  selectedSize: Size;
  onSizeSelect: (size: Size) => void;
}

const SizeSelector: React.FC<SizeSelectorProps> = ({
  selectedSize,
  onSizeSelect,
}) => {
  return (
    <div className="size-selector">
      {(Object.values(Size) as Size[]).map((size) => {
        return (
          <button
            key={size}
            className={`size-button ${selectedSize === size ? "selected" : ""}`}
            onClick={() => onSizeSelect(size)}
          >
            {sizeToString(size)}
          </button>
        );
      })}
    </div>
  );
};

export default SizeSelector;
