import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Size } from "../types/Size";
import { Product } from "../types/Product";
import { Products } from "../data/Products";
import { useSelectedLocation } from "../contexts/SelectedLocationContext";
import SizeSelector from "../components/SizeSelector";
import ColorSelector from "../components/ColorSelector";
import { Color } from "../types/Color";
import { useCart } from "../contexts/CartContext";
import { UrlParams } from "../types/Params";
import ImageZoom from "../components/ImageZoom";

const ProductPage = () => {
  const location = useLocation();
  const { category, productLine, product } = useParams<UrlParams>();
  const { setCategory, setProductLine, setProduct } = useSelectedLocation();
  const { setIsCartOpen, addToCart } = useCart();

  const [selectedProduct] = useState<Product>(
    location.state.selectedProduct ?? Products.find((p) => p.url === product)
  );
  const [selectedSize, setSelectedSize] = useState<Size>(null);
  const [selectedColor, setSelectedColor] = useState<Color>(Color.Army_Green);
  const [selectedImage, setSelectedImage] = useState<string>(
    selectedProduct.images?.length > 0 ? selectedProduct.images[0] : null
  );

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  const handleAddToCart = () => {
    setIsCartOpen(true);
    addToCart({
      product: selectedProduct,
      quantity: 1,
      size: selectedSize,
      color: selectedColor,
    });
  };

  useEffect(() => {
    setCategory(category);
    setProductLine(productLine);
    setProduct(product);
  }, [category, productLine, product, setCategory, setProductLine, setProduct]);

  return (
    <div className="product-page-container mx-auto p-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-8 gap-4">
      {/* Image Gallery on the Left */}
      <div className="col-span-1 lg:col-span-1 flex lg:flex-col lg:space-y-4 overflow-x-auto lg:overflow-visible">
        {selectedProduct?.images?.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`${selectedProduct.name}_${index}`}
            className={`cursor-pointer border w-24 h-24 md:w-32 md:h-32 lg:w-full lg:h-auto ${
              selectedImage === image ? "border-red-500" : "border-gray-200"
            }`}
            onClick={() => handleImageClick(image)}
          />
        ))}
      </div>

      {/* Featured Image in the Center */}
      <div className="col-span-1 lg:col-span-5">
        <ImageZoom
          imageUrl={selectedImage}
          imageAlt={`${selectedProduct.name}_center`}
        />
      </div>

      {/* Product Details on the Right */}
      <div className="col-span-1 lg:col-span-2 flex flex-col space-y-4">
        <h2 className="text-2xl font-semibold">{selectedProduct.name}</h2>
        <p className="text-lg text-gray-600">{selectedProduct.description}</p>
        <div className="space-y-2">
          <label
            htmlFor="size"
            className="text-lg font-bold block text-gray-700"
          >
            Size
          </label>
          <SizeSelector
            selectedSize={selectedSize}
            onSizeSelect={(size) => setSelectedSize(size)}
          />
          <label
            htmlFor="color"
            className="text-lg font-bold block text-gray-700"
          >
            Color
          </label>
          <ColorSelector
            selectedColor={selectedColor}
            onColorSelect={(color) => setSelectedColor(color)}
          />
        </div>
        <button
          type="button"
          className="cart-add-button"
          onClick={handleAddToCart}
          disabled={!selectedSize}
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default ProductPage;
