import React, { useEffect, useRef, useCallback } from "react";
import Header from "../components/Header";
import Cart from "../components/Cart";
import AboutPage from "./AboutPage";
import HomePage from "./HomePage";
import { Route, Routes } from "react-router-dom";
import ProductLinePage from "./ProductLinePage";
import { useSearch } from "../contexts/SearchContext";
import ProductPage from "./ProductPage";
import { useCart } from "../contexts/CartContext";
import HamburgerMenu from "../components/HamburgerMenu";
import { useHamburgerMenu } from "../contexts/HamburgerMenuContext";

const MainPage: React.FC = () => {
  const { isCartOpen, setIsCartOpen } = useCart();
  const { isHamburgerMenuOpen, setIsHamburgerMenuOpen } = useHamburgerMenu();
  const { setSearchQuery } = useSearch();

  const cartRef = useRef<HTMLDivElement>(null);
  const hamburgerMenuRef = useRef<HTMLDivElement>(null);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (cartRef.current && !cartRef.current.contains(event.target as Node)) {
        setIsCartOpen(false);
      }
      if (
        hamburgerMenuRef.current &&
        !hamburgerMenuRef.current.contains(event.target as Node)
      ) {
        setIsHamburgerMenuOpen(false);
      }
    },
    [setIsCartOpen, setIsHamburgerMenuOpen]
  );

  useEffect(() => {
    if (isCartOpen || isHamburgerMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, isCartOpen, isHamburgerMenuOpen]);

  // prettier-ignore
  return (
    <>
      <div className={`app-container ${isCartOpen && "opacity-75"}`}>
        <Header onSearch={handleSearch} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />

          {/* Themes */}
          <Route path="/categories/:category/:theme/seasons/:season/year/:year" element={<ProductLinePage />} />
          <Route path="/categories/:category/:theme/year/:year" element={<ProductLinePage />} />
          <Route path="/categories/:category/:theme" element={<ProductLinePage />} />
         
          {/* Clothing */}
          <Route path="/categories/:category/productlines/:productLine/products/:product" element={<ProductPage />}/>
          <Route path="/categories/:category/productlines/:productLine" element={<ProductLinePage />} />
          <Route path="/categories/:category" element={<ProductLinePage />} />
        </Routes>
      </div>

      {isCartOpen && (
        <div
          ref={cartRef}
          className="fixed right-0 top-0 p-4 w-80 bg-white shadow-lg z-50"
        >
          <Cart isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
        </div>
      )}

      {isHamburgerMenuOpen && (
        <div ref={hamburgerMenuRef}>
          <HamburgerMenu onSearch={handleSearch} />
        </div>
      )}
    </>
  );
};

export default MainPage;
