import React, { useState, useRef } from "react";
type ImageZoomProps = {
  imageUrl: string;
  imageAlt?: string;
};

type ZoomStyle = {
  backgroundImage: string;
  backgroundPosition: string;
  backgroundSize: string;
};

const DEFAULT_ZOOMSTYLE: ZoomStyle = {
  backgroundImage: null,
  backgroundPosition: null,
  backgroundSize: null,
};

const ImageZoom = ({ imageUrl, imageAlt }: ImageZoomProps) => {
  const [zoomStyle, setZoomStyle] = useState<ZoomStyle>(DEFAULT_ZOOMSTYLE);
  const [highlightStyle, setHighlightStyle] = useState({});
  const imgRef = useRef(null);

  const handleMouseMove = (e: any) => {
    const img = imgRef.current;
    const { left, top, width, height } = img.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;

    // Position zoomed image
    setZoomStyle({
      backgroundImage: `url(${imageUrl})`,
      backgroundPosition: `${x}% ${y}%`,
      backgroundSize: "300%", // Zoom level
    });

    // Position blue highlight
    const highlightSize = 100; // Size of the highlight square
    const highlightX = e.pageX - left - highlightSize / 2;
    const highlightY = e.pageY - top - highlightSize / 2;

    setHighlightStyle({
      left: `${Math.max(0, Math.min(highlightX, width - highlightSize))}px`,
      top: `${Math.max(0, Math.min(highlightY, height - highlightSize))}px`,
      width: `${highlightSize}px`,
      height: `${highlightSize}px`,
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle(DEFAULT_ZOOMSTYLE); // Reset zoom effect when mouse leaves
    setHighlightStyle({}); // Remove highlight box when mouse leaves
  };

  return (
    <div className="zoom-wrapper">
      <div
        className="image-container"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={imageUrl}
          alt={imageAlt}
          className="product-image"
          ref={imgRef}
        />
        <div className="highlight-box" style={highlightStyle}></div>
      </div>
      {zoomStyle?.backgroundImage != null && (
        <div className="zoom-overlay" style={zoomStyle}></div>
      )}
    </div>
  );
};

export default ImageZoom;
