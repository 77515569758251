type CardProps = {
  title?: string;
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
};

const Card = ({ title, onClick, className, children }: CardProps) => {
  return (
    <div className={`card ${className}`} onClick={onClick}>
      <div className="card-content">
        <h2>{title}</h2>
        {children}
      </div>
    </div>
  );
};

export default Card;
