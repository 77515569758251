// src/contexts/SelectedLocationContext.tsx

import React, { createContext, useState, ReactNode } from "react";

interface SelectedLocationContextType {
  category?: string;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  productLine?: string;
  setProductLine: React.Dispatch<React.SetStateAction<string>>;
  product?: string;
  setProduct?: React.Dispatch<React.SetStateAction<string>>;
  theme?: string;
  setTheme?: React.Dispatch<React.SetStateAction<string>>;
  season?: string;
  setSeason?: React.Dispatch<React.SetStateAction<string>>;
  year?: string;
  setYear?: React.Dispatch<React.SetStateAction<string>>;
}

const SelectedLocationContext = createContext<
  SelectedLocationContextType | undefined
>(undefined);

export const SelectedLocationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [category, setCategory] = useState<string>(null);
  const [productLine, setProductLine] = useState<string>(null);
  const [product, setProduct] = useState<string>(null);
  const [theme, setTheme] = useState<string>(null);
  const [season, setSeason] = useState<string>(null);
  const [year, setYear] = useState<string>(null);

  return (
    <SelectedLocationContext.Provider
      value={{
        category,
        setCategory,
        productLine,
        setProductLine,
        product,
        setProduct,
        theme,
        setTheme,
        season,
        setSeason,
        year,
        setYear,
      }}
    >
      {children}
    </SelectedLocationContext.Provider>
  );
};

export const useSelectedLocation = () => {
  const context = React.useContext(SelectedLocationContext);
  if (context === undefined) {
    throw new Error(
      "useSelectedLocation must be used within a SelectedLocationProvider"
    );
  }
  return context;
};
