// src/components/ProductList.tsx

import React from "react";
import ProductCard from "./ProductCard";
import { Product } from "../types/Product";

interface ProductListProps {
  products: Product[];
}

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  return (
    <div className="container py-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {products.map((product: Product, i: number) => (
        <ProductCard key={i} product={product} />
      ))}
    </div>
  );
};

export default ProductList;
