import { Link } from "react-router-dom";
import Card from "./Card";
import {
  getCategoryTitle,
  getLink,
  getThemeImageName,
} from "../extensions/helpers";
import { Theme } from "../types/Theme";
import { ProductCategory } from "../types/ProductCategory";
import { Season } from "../types/Season";

type ThemeCardProps = {
  theme: Theme;
  season?: Season;
  year?: number;
};

const ThemeCard = ({ theme, season, year }: ThemeCardProps) => {
  const category = ProductCategory.Theme;
  const title = getCategoryTitle(theme, season, year);

  const imageName = getThemeImageName({ category, theme, season, year });

  return (
    <Card title={title}>
      <img
        src={`/images/${imageName}.jpg`}
        alt={imageName}
        className="theme-card-image"
      />
      <Link
        to={getLink({
          category,
          theme,
          season,
          year,
        })}
        className="theme-card-button"
      >
        Shop {title}
      </Link>
    </Card>
  );
};

export default ThemeCard;
