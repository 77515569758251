// src/App.tsx

import React from "react";
import "./App.css"; // Import the App.css stylesheet
import { CartProvider } from "./contexts/CartContext";
import MainPage from "./pages/MainPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SearchProvider } from "./contexts/SearchContext";
import { SelectedLocationProvider } from "./contexts/SelectedLocationContext";
import { HamburgerMenuProvider } from "./contexts/HamburgerMenuContext";

const App: React.FC = () => {
  return (
    <SelectedLocationProvider>
      <SearchProvider>
        <HamburgerMenuProvider>
          <CartProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<MainPage />} />
              </Routes>
            </BrowserRouter>
          </CartProvider>
        </HamburgerMenuProvider>
      </SearchProvider>
    </SelectedLocationProvider>
  );
};

export default App;
