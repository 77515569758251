// src/components/ProductCard.tsx

import React from "react";
import { Product } from "../types/Product";
import Card from "./Card";
import { useNavigate } from "react-router-dom";
import { getLink } from "../extensions/helpers";

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const navigate = useNavigate();

  const handleClickCard = () => {
    navigate(
      getLink({
        category: product.productCategories[0],
        productLine: product.productLine,
        product: product.url,
      }),
      {
        state: { selectedProduct: product },
      }
    );
  };

  return (
    <Card className="hover:cursor-pointer" onClick={handleClickCard}>
      <img
        src={product.images[0]}
        alt={product.name}
        className="product-card-image"
      />
      <div className="p-2">
        <h3 className="text-lg text-center font-semibold mb-2">
          {product.name}
        </h3>
        <p className="text-md text-center font-bold mb-4">
          ${product.price.toFixed(2)}
        </p>
      </div>
    </Card>
  );
};

export default ProductCard;
