import { useParams } from "react-router-dom";
import ProductList from "../components/ProductList";
import { ProductCategory } from "../types/ProductCategory";
import {
  capitalizeFirstLetter,
  filterProducts,
  getCategoryTitle,
  parameterToCategoryType,
  parameterToProductLineType,
  parameterToSeasonType,
  parameterToThemeType,
  parameterToYear,
} from "../extensions/helpers";
import { useSearch } from "../contexts/SearchContext";
import { useSelectedLocation } from "../contexts/SelectedLocationContext";
import { useEffect, useState } from "react";
import { UrlParams } from "../types/Params";
import FilterSort from "../components/FilterSort";
import { SortBy } from "../types/SortBy";
import { Size } from "../types/Size";

const ProductLinePage = () => {
  // All product-related pages end up in this component, so all deep links go through here
  // Set all the params when loading any page
  const { category, productLine, product, theme, season, year } =
    useParams<UrlParams>();
  const { searchQuery } = useSearch();
  const {
    setCategory,
    setProductLine,
    setProduct,
    setTheme,
    setSeason,
    setYear,
  } = useSelectedLocation();

  const [size, setSize] = useState<Size>(undefined);
  const [sort, setSort] = useState<SortBy>(SortBy.Featured);

  const isTheme =
    parameterToCategoryType(category) === ProductCategory.Theme && !!theme;

  const products = filterProducts(
    searchQuery,
    parameterToCategoryType(category),
    parameterToProductLineType(productLine),
    parameterToThemeType(theme),
    parameterToSeasonType(season),
    parameterToYear(year),
    size,
    sort
  );

  useEffect(() => {
    setCategory(category);
    setProductLine(productLine);
    setProduct(product);
    setTheme(theme);
    setSeason(season);
    setYear(year);
  }, [
    category,
    productLine,
    product,
    theme,
    season,
    year,
    setCategory,
    setProductLine,
    setProduct,
    setTheme,
    setSeason,
    setYear,
  ]);

  return (
    <div className="px-4">
      <h1 className="text-3xl font-bold mt-8 poppins-text">
        {isTheme
          ? getCategoryTitle(
              parameterToThemeType(theme),
              parameterToSeasonType(season),
              parameterToYear(year)
            )
          : capitalizeFirstLetter(productLine) ?? "All"}
      </h1>
      <FilterSort
        size={size}
        sort={sort}
        setSize={setSize}
        setSort={setSort}
        productCount={products.length}
      />
      <ProductList products={products} />
    </div>
  );
};

export default ProductLinePage;
