// src/components/HamburgerMenu.tsx

import React, { useCallback, useState } from "react";
import TabDropdownList from "./TabDropdownList";
import debounce from "lodash.debounce";
import { useCart } from "../contexts/CartContext";
import { useHamburgerMenu } from "../contexts/HamburgerMenuContext";

interface HamburgerMenuProps {
  onSearch: (query: string) => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ onSearch }) => {
  const { isCartOpen, setIsCartOpen } = useCart();
  const { isHamburgerMenuOpen } = useHamburgerMenu();

  const [searchQuery, setSearchQuery] = useState("");

  // Create a debounced version of the onSearch function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      onSearch(query);
    }, 300),
    [onSearch]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };
  return (
    <div
      className={`fixed top-0 left-0 h-full z-50 transform transition-transform duration-500 ease-in-out md:px-6 bg-white flex flex-col items-start text-left justify-between ${
        isHamburgerMenuOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      {/* Navigation Tabs */}
      <nav>
        <TabDropdownList />
      </nav>

      {/* Search and Cart Buttons */}
      <div className="flex flex-col">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          className="p-2 border rounded-lg text-black hidden md:block"
          placeholder="Search..."
        />
        <button
          onClick={() => setIsCartOpen(!isCartOpen)}
          className="text-lg flex flex-row gap-4 py-4 pl-2"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 3h2l.4 2M7 13h10l1.1-5H5.9L7 13zm0 0l1.1 5H17l1.1-5M7 13v8a1 1 0 001 1h8a1 1 0 001-1v-8"
            ></path>
          </svg>
          <label>Cart</label>
        </button>
      </div>
    </div>
  );
};

export default HamburgerMenu;
