import React from "react";
import Card from "./Card";

const ContactUs = () => {
  return (
    <Card title="Contact Us">
      <p>
        Have questions or need assistance? We’re here to help! Reach out to us
        via any of the methods below, and we'll get back to you as soon as
        possible. Thank you and God Bless America!
      </p>
      <ul className="contact-info">
        <li>
          Email:{" "}
          <a href="mailto:info@americanaandco.com">
            support@americanaandcompany.com
          </a>
        </li>
        <li>
          Phone: <a href="tel:+1234567890">(201) 446-3301</a>
        </li>
      </ul>
    </Card>
  );
};

export default ContactUs;
