type SectionHeaderProps = {
  title: string;
};

const SectionHeader = ({ title }: SectionHeaderProps) => {
  return (
    <div className="header-with-bars">
      <div className="bar"></div>
      <h1 className="title">{title}</h1>
      <div className="bar"></div>
    </div>
  );
};

export default SectionHeader;
