// src/components/PaymentForm.tsx

import React, { useCallback, useEffect } from "react";
import { PaymentStatus } from "../types/PaymentStatus";
import { PaymentResponse } from "../types/PaymentResponse";

// Declare Square as a global variable
declare global {
  interface Window {
    Square: any;
  }
}

type PaymentFormProps = {
  amount: number;
  paymentResponse: PaymentResponse;
  setPaymentResponse: React.Dispatch<React.SetStateAction<PaymentResponse>>;
};

const PaymentForm = ({
  amount,
  paymentResponse,
  setPaymentResponse,
}: PaymentFormProps) => {
  const handlePayment = useCallback(
    async (nonce: string) => {
      const amount = 5000; // e.g., $50.00
      try {
        const response = await fetch("/.netlify/functions/create-payment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount, sourceId: nonce }),
        });

        const data = await response.json();
        if (response.ok) {
          setPaymentResponse({ paymentStatus: PaymentStatus.Success });
          alert("Payment successful!");
        } else {
          setPaymentResponse({
            paymentStatus: PaymentStatus.Failure,
            errorMessage: data.error,
          });
          alert(`Payment failed: ${data.error}`);
        }
      } catch (error) {
        setPaymentResponse({
          paymentStatus: PaymentStatus.Failure,
          errorMessage: error.message,
        });
        alert(`Payment failed: ${error.message}`);
      }
    },
    [setPaymentResponse]
  );

  useEffect(() => {
    const loadSquareScript = () => {
      const script = document.createElement("script");
      script.src = "https://sandbox.web.squarecdn.com/v1/square.js"; // Use sandbox URL for testing
      script.async = true;
      script.onload = () => initializeSquarePayments();
      document.body.appendChild(script);
    };

    const initializeSquarePayments = async () => {
      try {
        const payments = window.Square.payments(
          process.env.REACT_APP_SQUARE_APPLICATION_ID!,
          process.env.REACT_APP_SQUARE_LOCATION_ID!
        );

        const card = await payments.card();
        await card.attach("#card-container");

        const paymentForm = document.getElementById("payment-form");
        paymentForm!.addEventListener("submit", async (event) => {
          event.preventDefault();

          const result = await card.tokenize();
          if (result.status === "OK") {
            handlePayment(result.token);
          } else {
            console.error(result.errors);
          }
        });
      } catch (error) {
        console.error("Failed to initialize Square payments:", error);
      }
    };

    loadSquareScript();
  }, [handlePayment]);

  return (
    <form id="payment-form">
      <div id="card-container"></div>
      <button
        type="submit"
        className="cart-checkout-button"
        disabled={amount <= 0}
      >
        Pay Now
      </button>
    </form>
  );
};

export default PaymentForm;
