import React from "react";
import { Color } from "../types/Color";
import { typeToString } from "../extensions/helpers";

interface ColorSelectorProps {
  selectedColor: Color;
  onColorSelect: (color: Color) => void;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({
  selectedColor,
  onColorSelect,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onColorSelect(Color[event.target.value as keyof typeof Color]);
  };

  return (
    <select
      id="color"
      name="color"
      className="border border-gray-300 p-2 rounded-lg w-full"
      value={selectedColor}
      onChange={handleChange}
    >
      {Object.keys(Color).map((color) => {
        return (
          <option key={color} value={Color[color as keyof typeof Color]}>
            {typeToString(color)}
          </option>
        );
      })}
    </select>
  );
};

export default ColorSelector;
